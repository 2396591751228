<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab">
            <a-icon type="project" /> {{ $t("TableContent") }}
          </span>
          <!-- lang tabs -->
          <a-row
            align="middle"
            class="flex-baseline"
            style="margin-bottom: 15px"
          >
            <a-col :span="6" class="form_title"> {{ $t("Lang") }}: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title">
              {{ $t("TagsColTitle") }}:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.name[langTab]"
                placeholder="Введите название"
                @change="slugCompute($event, langTab, 'hashtag', form.name)"
              />
            </a-col>
          </a-row>
          <!-- slug -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title"> {{ $t("Alias") }}: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-row align="middle" type="flex">
                <span class="mr-1 link" @click="copySlug">
                  <a-icon :style="{ fontSize: '22px' }" type="copy" />
                </span>

                <span class="flex-1">
                  <a-input
                    id="slug-input"
                    :value="form.slug"
                    :placeholder="$t('EnterAlias')"
                    @input="onSlugChange"
                  />
                </span>
              </a-row>
              <div v-if="!form.slug" class="error-text">{{ $t("EnterAlias") }}:</div>
              <div v-if="errors.slug" class="z-error-text">
                {{ $t("NoAlias") }}:
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />{{ $t("Cencel") }}
                </a-button>
                <a-button type="primary" @click="submitTag">
                  <a-icon type="check" />{{ $t("Add") }}
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        slug: "",
      },
      langTab: "oz",
      errors: {
        slug: "",
      },
    };
  },
  mounted() {
    this.$on("update:slug", this.onSlugChange);
  },
  methods: {
    onSlugChange(e) {
      const text = (e.target.value || "")
        .replace(/( )/gi, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\\-]/gi, "");

      this.slugCheck({ table: "hashtag", slug: text });

      this.form.slug = text;
    },
    copySlug() {
      this.copyStringToClipboard(`/${this.$options.name}/${this.form.slug}`);
      this.$message.success("ЧПУ скопирован");
    },
    async submitTag() {
      try {
        let res = await this.$api.post("/admin/hashtag/create", this.form);
        console.log(res);
        if (res.status == 200 && res.data.status) {
          this.$message.success("Успешно создана!!!");
          this.$router.push({ name: "tags" });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
