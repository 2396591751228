<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab">
            <a-icon type="project" />{{ $t("TableContent") }}
          </span>
          <!-- lang tabs -->
          <a-row
            align="middle"
            class="flex-baseline"
            style="margin-bottom: 15px"
          >
            <a-col :span="6" style="text-align: right; padding-right: 8px">
              {{ $t("Lang") }}:
            </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title">
              {{ $t("TagsColTitle") }}:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-input
                v-model="form.name[langTab]"
                placeholder="Введите название"
                @change="slugCompute($event, langTab, 'hashtag', form.name)"
              />
            </a-col>
          </a-row>
          <!-- slug -->
          <a-row align="middle" class="flex-baseline">
            <a-col :span="6" class="form_title"> {{ $t("Alias") }}: </a-col>
            <a-col :span="12" class="sm-mb">
              <a-row align="middle" type="flex">
                <span class="mr-1 link" @click="copySlug">
                  <a-icon :style="{ fontSize: '22px' }" type="copy" />
                </span>

                <span class="flex-1">
                  <a-input
                    id="slug-input"
                    :value="form.slug"
                    :placeholder="$t('EnterAlias')"
                    @input="onSlugChange"
                  />
                </span>
              </a-row>
              <div v-if="!form.slug" class="error-text">{{ $t("EnterAlias") }}:</div>
              <div v-if="errors.slug" class="z-error-text">
                {{ $t("NoAlias") }}:
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 20px">
            <a-col :span="6" />
            <a-col :span="12">
              <a-button :disabled="pending" @click="$router.go(-1)">
                <a-icon type="close" />{{ $t("Cancel") }}
              </a-button>
              <a-button
                :loading="pending"
                type="primary"
                @click="submitUsefullLinks"
              >
                {{ $t("Save") }}
              </a-button>
            </a-col>
          </a-row>
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
export default {
  data ()  {
    return {
      form: {
        name: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        slug: "",
      },
      errors: {
        slug: "",
      },
      langTab: "oz",
      pending: false,
    };
  },
  mounted() {
    this.$on("update:slug", this.onSlugChange);
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  async created() {
    const { data } = await this.$store.dispatch(
      "tags/fetchTagById",
      this.$route.params.id
    );
    this.form = data;
  },
  methods: {
    onSlugChange(e) {
      const text = (e.target.value || "")
        .replace(/( )/gi, "-")
        .toLowerCase()
        .replace(/[^a-zA-Z0-9\\-]/gi, "");

      this.slugCheck({ table: "hashtag", slug: text });

      this.form.slug = text;
    },
    copySlug() {
      this.copyStringToClipboard(`/${this.$options.name}/${this.form.slug}`);
      this.$message.success("ЧПУ скопирован");
    },
    async submitUsefullLinks() {
      this.pending = true;
      const id = this.$route.params.id;
      try {
        let res = await this.$api.put(`/admin/hashtag/${id}`, this.form);
        console.log(res);
        if (res.status == 200 && res.data.status) {
          this.$message.success("Успешно сохранено!");
          this.$router.push({ name: "tags" });
        }
      } catch (error) {
        console.error(error);
        this.$sentry.captureMessage(error);
      }
      this.pending = false;
    },
  },
};
</script>

<style></style>
